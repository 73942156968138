import React from 'react';
import ABI from './ABI.json';
import Web3 from 'web3/dist/web3.min.js';

var account = null;
var contract = null;

const ADDRESS = "0x06419C312E00E6e0243C8bec34Abd9F6aE60992A";

async function connectwallet() {
    if (window.ethereum) {
        var web3 = new Web3(window.ethereum);
        await window.ethereum.send('eth_requestAccounts');
        var accounts = await web3.eth.getAccounts();
        account = accounts[0];
        document.getElementById('wallet-address').textContent = account;
        contract = new web3.eth.Contract(ABI, ADDRESS);
    }
}
async function mintClassicPickaxe() {
    if (window.ethereum) {
      var _mintAmount = Number(document.querySelector("[name=amount]").value);
      var mintRate = Number(await contract.methods.tokenPriceClassicPickaxe().call());
      var totalAmount = mintRate * _mintAmount;
    contract.methods.mintClassicPickaxe(_mintAmount).send({ from: account, value: String(totalAmount)})
  }
}
function Pickaxes () {
      return (
        <div>
          <section
            class="skrollable u-clearfix u-image u-parallax u-valign-middle-md u-valign-middle-sm u-valign-middle-xs u-section-1"
            id="carousel_f0c6"
            data-image-width="2000"
            data-image-height="1333"
          >
            <div class="u-clearfix u-sheet u-sheet-1">
            <p class="u-align-center u-custom-font u-text u-text-palette-1-dark-2 u-text-1">P<span class="u-text-palette-3-base">I</span>CK<span class="u-text-palette-3-base">A</span>XE<span class="u-text-palette-3-base">S</span>
        </p>
        <h3 class="u-align-center u-custom-font u-text u-text-grey-70 u-text-2">
          <span class="u-text-grey-30" style={{ fontsize: "3rem;" }}>HODL  </span>
          <span class="u-text-palette-1-base"></span>
          <span class="u-text-black">
            <span class="u-text-custom-color-3">
              <span class="u-text-white">your way into the  </span>
              <span style={{ fontsize: "3rem;" }} class="u-text-palette-1-dark-2">Voxelverse</span>
            </span>
            <span class="u-text-white" style={{ fontsize: "3rem;" }}></span>
          </span>
        </h3>
              <div class="u-border-2 u-border-grey-75 u-container-style u-group u-opacity u-opacity-10 u-palette-5-dark-3 u-shape-rectangle u-group-1">
          <div class="u-container-layout u-valign-top u-container-layout-1">
            <h4 class="u-align-center u-custom-font u-text u-text-white u-text-3">
            <span class="u-text-palette-1-dark-1">GAMIFICATION</span>
              <br />
              <br />
              <span style={{ fontsize: "1.25rem;" }} class="u-text-custom-color-1">&nbsp;<span class="u-text-white">Hold&nbsp;Pickaxes and receive dripped PROSPECT rewards</span>
                <br />
                <br />
                <span class="u-text-palette-4-base">
                  <span class="u-text-white">Use PROSPECT and burn&nbsp;3 Pickaxes&nbsp;​to mint a PROSPECTO​R NFT</span>
                </span>
              </span>
              <br />
              <br />
              <span style={{ fontsize: "1.25rem;" }}>Access Voxelverse gameplay, mine&nbsp;PROSPECT&nbsp;​and additional rewards</span>
              <br />
              <br />
              <span style={{ fontsize: "1.25rem;" }}>Utilize PROSPECT to purcase in game assets for power ups, customizations land items and much more</span>
            </h4>
          </div>
              </div>
              <h4 class="u-align-center u-custom-font u-text u-text-white u-text-4">
                &nbsp;<span class="u-text-palette-3-base">Free Mint</span>
                <br />
                <span class="u-text-palette-1-dark-1">1 per wallet</span>
                <br />
              </h4>
              <button class="u-align-center u-border-2 u-border-black u-btn u-button-style u-custom-font u-hover-palette-4-dark-2 u-palette-4-dark-1 u-text-black u-text-hover-white u-btn-1" onClick={connectwallet}>Connect to Mint</button>
              <h4 class="u-align-center u-custom-font u-text-white" id='wallet-address'>not connected</h4>
              <div class="u-align-center u-list u-list-1">
                <div class="u-repeater u-repeater-1">
                  <div
                    class="u-align-center u-border-6 u-border-black u-container-style u-image u-list-item u-repeater-item u-shape-rectangle u-image-1"
                    data-image-width="2000"
                    data-image-height="1333"
                  >
                    <div class="u-container-layout u-similar-container u-container-layout-2">
                      <h3 class="u-align-center u-custom-font u-text u-text-default u-text-5">
                        Round 1: 1000 Pickaxes
                      </h3>
                      <img
                        class="u-image u-image-default u-preserve-proportions u-image-2"
                        src="/images/2.png"
                        alt=""
                        data-image-width="1000"
                        data-image-height="1000"
                      />
                      <input class="u-border-2 u-border-black u-text u-custom-font" type="number" name="amount" defaultValue="1" min="1" max="1"/>
                      <button class="u-border-2 u-border-black u-btn u-button-style u-custom-font u-hover-palette-4-dark-2 u-palette-4-dark-1 u-text-black u-text-hover-white u-btn-2" onClick={mintClassicPickaxe}>mint</button>
                      <h5 class="u-align-center u-custom-font u-text u-text-6">
                        Secondary Marketplaces
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div class="u-align-center u-clearfix u-gutter-14 u-layout-wrap u-layout-wrap-1">
                <div class="u-layout">
                  <div class="u-layout-col">
                    <div class="u-size-30">
                      <div class="u-layout-row">
                        <div
                          class="u-container-style u-layout-cell u-center-cell u-palette-1-dark-1 u-size-15 u-layout-cell-1"
                          src=""
                        >
                          <div class="u-container-layout u-valign-middle u-container-layout-3">
                            <img
                              class="u-image u-image-default u-preserve-proportions u-image-3"
                              src="/images/2.png"
                              alt=""
                              data-image-width="1000"
                              data-image-height="1000"
                            />
                          </div>
                        </div>
                        <div class="u-align-left u-container-style u-grey-70 u-layout-cell u-size-15 u-layout-cell-2">
                          <div class="u-container-layout u-container-layout-4">
                            <h3
                              class="u-align-center u-custom-font u-text u-text-palette-4-base u-text-7"
                              data-animation-name="counter"
                              data-animation-event="scroll"
                              data-animation-duration="3000"
                            >
                              333
                            </h3>
                            <p class="u-align-center u-custom-font u-text u-text-white u-text-8">
                              {" "}
                              MODERN SCIFI
                              <br />
                            </p>
                          </div>
                        </div>
                        <div
                          class="u-container-style u-grey-10 u-layout-cell u-size-15 u-layout-cell-3"
                          src=""
                        >
                          <div class="u-container-layout u-valign-middle u-container-layout-5">
                            <img
                              class="u-image u-image-default u-preserve-proportions u-image-4"
                              src="/images/3.png"
                              alt=""
                              data-image-width="1000"
                              data-image-height="1000"
                            />
                          </div>
                        </div>
                        <div class="u-align-left u-black u-container-style u-layout-cell u-right-cell u-size-15 u-layout-cell-4">
                          <div class="u-container-layout u-container-layout-6">
                            <h3
                              class="u-align-center u-custom-font u-text u-text-palette-3-base u-text-9"
                              data-animation-name="counter"
                              data-animation-event="scroll"
                              data-animation-duration="3000"
                            >
                              1
                            </h3>
                            <p class="u-align-center u-custom-font u-text u-text-palette-3-base u-text-10">
                              LEGENDARY
                              <br />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="u-size-30">
                      <div class="u-layout-row">
                        <div class="u-align-left u-container-style u-layout-cell u-left-cell u-palette-1-dark-1 u-size-15 u-layout-cell-5">
                          <div class="u-container-layout u-container-layout-7">
                            <h3
                              class="u-align-center u-custom-font u-text u-text-palette-4-base u-text-11"
                              data-animation-name="counter"
                              data-animation-event="scroll"
                              data-animation-duration="3000"
                            >
                              333
                            </h3>
                            <p class="u-align-center u-custom-font u-text u-text-12">
                              <span style={{ weight: "700;" }}>CLASSIC</span>
                              <br />
                            </p>
                          </div>
                        </div>
                        <div
                          class="u-container-style u-grey-70 u-layout-cell u-size-15 u-layout-cell-6"
                          src=""
                        >
                          <div class="u-container-layout u-valign-middle u-container-layout-8">
                            <img
                              class="u-image u-image-default u-preserve-proportions u-image-5"
                              src="/images/1.png"
                              alt=""
                              data-image-width="1000"
                              data-image-height="1000"
                            />
                          </div>
                        </div>
                        <div class="u-align-left u-container-style u-grey-10 u-layout-cell u-size-15 u-layout-cell-7">
                          <div class="u-container-layout u-container-layout-9">
                            <h3
                              class="u-align-center u-custom-font u-text u-text-palette-4-base u-text-13"
                              data-animation-name="counter"
                              data-animation-event="scroll"
                              data-animation-duration="3000"
                            >
                              333
                            </h3>
                            <p class="u-align-center u-custom-font u-text u-text-black u-text-14">
                              FANTASY
                              <br />
                            </p>
                          </div>
                        </div>
                        <div
                          class="u-black u-container-style u-layout-cell u-right-cell u-size-15 u-layout-cell-8"
                          src=""
                        >
                          <div class="u-container-layout u-valign-middle u-container-layout-10">
                            <img
                              class="u-image u-image-default u-preserve-proportions u-image-6"
                              src="/images/ProspectorsCoinv2.png"
                              alt=""
                              data-image-width="1920"
                              data-image-height="1920"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            class="u-clearfix u-image u-shading u-section-2"
            id="carousel_7e86"
            data-image-width="914"
            data-image-height="514"
          >
            <div class="u-clearfix u-sheet u-sheet-1">
            <h2 class="u-align-center u-custom-font u-hover-feature u-text u-text-1">
          <span class="u-text-palette-3-base">
            <span class="u-text-black" style={{ fontsize: "6rem;" }}>P</span>
            <span class="u-text-black">ROSPECTORS</span>&nbsp;
          </span> NFT
        </h2>
              <h3 class="u-custom-font u-text u-text-2">Ready to mint?</h3>
              <a
                href="http://prospectorsnft.io"
                class="u-align-center u-border-2 u-border-black u-btn u-button-style u-custom-font u-hover-palette-4-dark-2 u-palette-4-dark-1 u-text-black u-text-hover-white u-btn-1"
              >
                connect wallet
              </a>
              <div class="u-clearfix u-gutter-18 u-layout-wrap u-layout-wrap-1">
                <div class="u-layout">
                  <div class="u-layout-row">
                    <div class="u-container-style u-layout-cell u-palette-1-dark-1 u-size-30 u-layout-cell-1">
                      <div class="u-container-layout u-container-layout-1">
                        <h5 class="u-align-center u-custom-font u-text u-text-3">
                          Mint a Johnny Newcome Prospector
                          <br />
                          <br />
                          0.1{" "}
                          <span
                            style={{ weight: "700;" }}
                            class="u-text-palette-4-base"
                          >
                            ETH
                          </span>
                        </h5>
                        <p class="u-align-center u-custom-font u-font-montserrat u-text u-text-4">
                          <span style={{ weight: "700;" }}>500</span> Reserved
                          for <span style={{ weight: "700;" }}>ETH</span>{" "}
                          minting
                        </p>
                        <img
                          class="u-align-center u-image u-image-default u-preserve-proportions u-image-1"
                          src="/images/MeetJohnny.png"
                          alt=""
                          data-image-width="1000"
                          data-image-height="1000"
                        />
                        <a
                          href="https://nicepage.com/website-builder"
                          class="u-border-2 u-border-black u-btn u-button-style u-custom-font u-hover-palette-4-base u-none u-text-hover-white u-btn-2"
                        >
                          MINT
                        </a>
                        <h4 class="u-align-center u-custom-font u-text u-text-5">
                          <span class="u-icon"></span>&nbsp;​Includes
                          <br />
                          Voxelverse gameplay access
                          <br />
                          'holders only' Land mint
                          <b>
                            &nbsp;
                            <span class="u-text-palette-3-light-1">
                              Goldlist
                            </span>
                          </b>
                        </h4>
                      </div>
                    </div>
                    <div class="u-align-left u-black u-container-style u-layout-cell u-size-30 u-layout-cell-2">
                      <div class="u-container-layout u-container-layout-2">
                        <h5 class="u-align-center u-custom-font u-text u-text-6">
                          Mint a Johnny Newcome Prospector
                          <br />
                          <br />
                          6000.00&nbsp;
                          <span
                            style={{ weight: "700;" }}
                            class="u-text-palette-4-base"
                          >
                            PROSPECT
                          </span>
                        </h5>
                        <p class="u-align-center u-custom-font u-font-montserrat u-text u-text-7">
                          <span style={{ weight: "700;" }}>500</span> Reserved
                          for <span style={{ weight: "700;" }}>$PROSPECT</span>{" "}
                          minting
                        </p>
                        <img
                          class="u-align-center u-image u-image-default u-preserve-proportions u-image-2"
                          src="/images/MeetJohnny.png"
                          alt=""
                          data-image-width="1000"
                          data-image-height="1000"
                        />
                        <a
                          href="https://nicepage.com/website-builder"
                          class="u-border-2 u-border-white u-btn u-button-style u-custom-font u-hover-palette-4-base u-none u-text-hover-white u-btn-3"
                        >
                          MINT
                        </a>
                        <h4 class="u-align-center u-custom-font u-text u-text-8">
                          {" "}
                          Includes
                          <br />
                          Voxelverse gameplay&nbsp;access
                          <br />
                          'holders only' Land mint{" "}
                          <span
                            style={{ weight: "700;" }}
                            class="u-text-palette-3-base"
                          >
                            Goldlist
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="u-backlink u-clearfix u-grey-80">
            <p class="u-text">
              <span>Prospectors NFT</span>
            </p>
            <p class="u-text">
              <span>and</span>
            </p>
            <p class="u-text">
              <span>The Voxelverse</span>
            </p>
          </section>
        </div>
      );
}

export default Pickaxes;