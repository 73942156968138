import React from 'react';
import './App.css';
import Pickaxes3 from './Pickaxes3.js';

function App() {
  return (
    <>
      <Pickaxes3 />
    </>
  );
}

export default App;
